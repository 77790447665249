.pricing {
  &__item {
    background-color: $bg-one;
    padding: 40px 50px;
    text-align: center;
    transition: 0.3s;
    &:hover {
      box-shadow: 5px 5px $color-secondary;
    }
    &.active {
      box-shadow: 5px 5px $color-secondary;
    }
    @include respond(laptop) {
      padding: 20px;
    }
    @include respond(tablet) {
      padding: 40px 50px;
    }
  }

  &__price {
    font-size: 40px;
  }
  &__list {
    padding-left: 0;
    margin: 40px 0;
    li {
      list-style: none;
      padding: 10px 0;
      &:not(:last-child) {
        border-bottom: 1px solid $color-primary;
      }
      svg {
        font-size: 20px;
        margin-right: 15px;
      }
    }
  }
}

.bg-one {
  .pricing__item {
    background-color: $color-light;
  }
  .pricing__list li:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }
}

.offset-xl-3 {
  margin-left: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-input {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.error-message {
  color: #d32f2f;
  font-size: 0.9rem;
}

.success-message {
  color: #388e3c;
  font-size: 0.9rem;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.cancel-button {
  background-color: #f5f5f5;
  color: #333;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.pay-button {
  background-color: #1976d2;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:disabled,
.pay-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
