

/*
* Colors
*/
$color-primary: #AD1457;
$color-secondary: #F06292;
$color-tertiary: #F06292;


$color-light: #ffffff;
$color-dark: #2f4858;
$color-gray: #676768;
$color-border: #eff0f7;
$color-success: #00b374;
$color-warning: #ffbb1c;
$color-danger: #ca3604;
$color-shadow: rgba(1, 6, 33, 0.06);
$bg-one: #F06292;
// $bg-one: #87CEEB;

