.header {
  position: absolute;
  width: 100%;
  transition: border-radius 0.2s, width 350ms ease;
  &.navbar_fixed {
    position: fixed;
    width: 100%;
    // background: $color-light;
    z-index: 100;
    box-shadow: 0 2px 28px 0 $color-shadow;
    // animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    .navbar {
      margin-top: 0px;
    }
  }
  .navbar {
    border: 0px;
    // margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all 0.3s;
    transition: all 0.4s ease-in-out;
    .navbar-brand {
      padding: 0px;
    }

    .navbar-nav li a {
      color: $color-dark;
    }

    .navbar-nav li a:hover,
    .navbar-nav li a.active {
      color: $color-primary;
      background: none;
    }

    .navbar-nav li a:hover:after,
    .navbar-nav li a.active:after {
      opacity: 1;
      bottom: -12px;
    }
  }
  .navbar-expand-lg {
    // background-color: $color-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.4s;
  }
  .navbar-toggler {
    position: relative;
    width: 30px;
    height: 20px;
    border-radius: 0px;
    padding: 0px;
    border: 0px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 50%;
      // background: $color-dark;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      &:nth-child(even) {
        left: 50%;
        border-radius: 0 9px 9px 0;
      }
      &:nth-child(odd) {
        left: 0px;
        border-radius: 9px 0 0 9px;
      }
      &:nth-child(1),
      &:nth-child(6) {
        transform: rotate(45deg);
      }
      &:nth-child(2),
      &:nth-child(5) {
        transform: rotate(-45deg);
      }
      &:nth-child(1) {
        left: 0px;
        top: 4px;
      }
      &:nth-child(2) {
        left: calc(50% - 4px);
        top: 4px;
      }
      &:nth-child(3) {
        left: -50%;
        opacity: 0;
      }
      &:nth-child(4) {
        left: 86%;
        opacity: 0;
      }
      &:nth-child(5) {
        left: 0px;
        top: 14px;
      }
      &:nth-child(6) {
        left: calc(50% - 4px);
        top: 14px;
      }
    }
    &.collapsed {
      span {
        &:nth-child(1),
        &:nth-child(2) {
          top: 2px;
        }
        &:nth-child(3),
        &:nth-child(4) {
          top: 9px;
        }
        &:nth-child(5),
        &:nth-child(6) {
          top: 16px;
        }
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(1),
        &:nth-child(6) {
          transform: rotate(0deg);
        }
        &:nth-child(3) {
          opacity: 1;
          left: 0;
        }
        &:nth-child(4) {
          opacity: 1;
          left: calc(50% - 4px);
        }
      }
    }
  }
}
.dropdown-toggle.active.show span {
  transform: rotate(180deg);
  color: $color-primary;
}
.menu {
  > .nav-item {
    position: relative;
    transition: all 0.2s linear;
    border-bottom: 1px solid transparent;
    margin-left: 15px;

    @include respond(desktop) {
      margin-left: 0;
    }
    a {
      padding-top: 8px;
      padding-bottom: 8px;
      display: block;
      cursor: pointer;
      display: flex;
      gap: 4px;
      align-items: center;
      &::after {
        transition: all 0.4s cubic-bezier(0.3, 0.1, 0.58, 1);
        opacity: 0;
        content: '';
        width: 100%;
        width: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        height: 2px;
        // background: $color-primary;
        position: absolute;
        bottom: -20px;
        @include respond(laptop) {
          left: 10px;
          background: inherit;
        }
      }
    }
    &.submenu {
      .dropdown-menu {
        margin: 0px;
        border: 0px;
        border-radius: 0px;
        left: -30px;
        min-width: 200px;
        padding: 15px 0px;
        // background: $color-light;
        top: 100%;
        box-shadow: 0px 6px 20px 0px $color-shadow;
        @media (min-width: 992px) {
          transform: translateY(20px);
          transition: all 0.3s ease-in;
          opacity: 0;
          visibility: hidden;
          display: block;
        }

        .nav-item {
          display: block;
          width: 100%;
          padding: 0px 30px;
          position: relative;
          &:not(:first-child) {
            margin-top: 14px;
          }
          .nav-link {
            padding: 0px;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 500;
            color: $color-dark;
            transition: color 0.2s linear;
            &:after {
              display: none;
            }
          }
          &:hover,
          &:focus {
            > .nav-link {
              color: $color-primary;
            }
          }

          > .dropdown-menu {
            transition: transform 0.3s ease-in;
            &:before {
              display: none;
            }
            @media (min-width: 992px) {
              position: absolute;
              left: 100%;
              top: -15px;
              opacity: 0;
              display: block;
              visibility: hidden;
              transform: translateY(20px);
              transition: all 0.3s ease-in;
            }
          }
        }
      }
    }
    &:hover {
      .nav-link {
        color: $color-primary;
      }
      @media (min-width: 992px) {
        .dropdown-menu {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          > .nav-item {
            &:hover {
              @media (min-width: 992px) {
                .dropdown-menu {
                  transform: scaleY(1);
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar-nav > li .sub-menu-toggle {
    position: absolute;
    top: 0;
    right: 0;
    // background: $color-dark;
    width: 100%;
    height: 38px;
    width: 38px;
    margin-right: 0px;
    cursor: pointer;
    text-align: center;
    z-index: 1;
    border: 3px solid $color-light;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-light;
  }
}

@include respond(laptop) {
  .navbar .navbar-collapse {
    position: absolute;
    left: 0;
    top: 100%;
    // background: $color-light;
    box-shadow: 4px 6px 10px $color-shadow;
    z-index: 8;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    max-height: 300px;
    overflow-y: scroll;
  }
  .menu > .nav-item {
    margin: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    &.submenu .dropdown-menu {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 10px;
      .nav-item {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.dropdown-toggle::after {
  border-top: 0px;
}

.navbar {
  position: fixed; /* Fix the navbar to the top of the page */
  top: 0;
  left: 0;
  width: 100%; /* Make it span the entire width */
  z-index: 1000; /* Ensure it stays above other elements */
  background-color: rgba(255, 255, 255, 1); /* Slightly transparent background */
  backdrop-filter: blur(10px); /* Optional: Adds a blur effect */
  border-bottom: 1px solid #ddd; /* Optional: Border for better visibility */
  transition: background-color 0.3s ease-in-out;
}

.navbar:hover {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly less transparent on hover */
}

ul.navbar-nav.menu.ms-lg-auto { margin-left: 41% !important; }